import React, { useState, useEffect } from 'react';
import { Grid, Card, CardContent, Typography, Button, Box, Table, TableBody, TableCell, TextField, TableHead, TableRow, TableSortLabel, Select, MenuItem, FormControl, InputLabel, ListItemIcon, ListItemText, ThemeProvider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import BarChartIcon from "@mui/icons-material/BarChart";
import ShowChartIcon from '@mui/icons-material/ShowChart';
import ListIcon from '@mui/icons-material/List';
import logopng from './pics/logo.png';
import BankIcon from '@mui/icons-material/AccountBalance';
import RechargeIcon from '@mui/icons-material/AccountBalanceWallet';
import UpdateIcon from '@mui/icons-material/Update';
import CardIcon from '@mui/icons-material/CreditCard';
import SliderCardWhite from "./pics/card-white.webp";
import SliderCardBlue from "./pics/card-blue.webp";
import BusinessGrayCard from "./pics/business_gray_card.png";
import useMediaQuery from '@mui/material/useMediaQuery';
import { changeLanguage, getCookieValue } from './App';
import Loading from './Loading';
import { format } from 'date-fns';
import CircularProgress from '@mui/material/CircularProgress';
import { fetchAllAccountBalances, fetchBankAccountBalance, fetchPageData, isBusinessAccount } from './Util_API_calls';
import { t, number_to_italian_currency, italian_currency_to_number_with_negative_amounts, number_to_italian_currency_nocents, number_to_italian_nosymbol } from './Util_format';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Modal_StartCardEdit from './Modal_StartCardEdit';
import { Cell, LineChart, Line, XAxis, YAxis, ResponsiveContainer, CartesianGrid, BarChart, Bar, Text, Tooltip } from 'recharts';
import Slider from 'react-slick';
import TablePagination from '@mui/material/TablePagination';
import { cardStyles_bg, textStyle_big_account, textStyle_small_dark, textStyle_big_dark, textStyle_small_light, blue_title, theme } from './ReactStyles';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import Slide from '@mui/material/Slide';
import { useIntercom } from 'react-use-intercom';
import { getCurrencySymbol } from './Util_currencies';
import { isCreditTransaction, isNOOPTransaction, isTopUpCardTransaction, transactionTypeKey } from './Util_app';

function VariationColumnChart({ userAccounts, userData }) {
  const [account, setAccount] = useState('');
  const [accountType, setAccountType] = useState(null);

  const handleChange = (event) => {
    const selected = event.target.value;
    setAccountType(selected.type);
    setAccount(selected); // Store the entire selected object
  };

  useEffect(() => {
    if (!account && userAccounts.length > 0) {
      const firstAccount = userAccounts.find(acc => acc.type === 'account');
      if (firstAccount) {
        setAccount(firstAccount);
        setAccountType(firstAccount.type);
      }
    }
  }, [userAccounts, account]);

  const initializeChartData = () => {
    return [
      { name: t("col_chart_incomes"), value: 0 },
      { name: t("col_chart_expenses"), value: 0 }
    ];
  };

  let ColumnsChart = initializeChartData();

  const processTransactions = (
    transactions,
    isIncoming,
    isAccountTransaction
  ) => {
    if (isAccountTransaction) {
      if (isIncoming) {
        return transactions.reduce((total, tx) => {
          if (
            tx.status !== "REJECTED" &&
            (tx.transaction_type === "FUNDING" ||
              tx.transaction_type === "EXCHANGE")
          ) {
            return total + Math.abs(tx.amount);
          }
          return total;
        }, 0);
      } else {
        return transactions.reduce((total, tx) => {
          if (
            tx.status !== "REJECTED" &&
            (tx.transaction_type === "TRANSFER" ||
              tx.transaction_type === "INVOICE")
          ) {
            return total + Math.abs(tx.amount);
          }
          return total;
        }, 0);
      }
    }
    if (isIncoming) {
      return transactions.reduce((total, tx) => {
        if (tx.direction === "CREDIT") {
          return total + Math.abs(tx.amount);
        }
        return total;
      }, 0);
    } else {
      return transactions.reduce((total, tx) => {
        if (tx.direction === "DEBIT") {
          return total + Math.abs(tx.amount);
        }
        return total;
      }, 0);
    }
  };

  if (userData && account) {
    let transactions = [];
    if (account.type === 'account' && userData.current_month_transactions?.bank_accounts?.[account.account_id]) {
      transactions = userData.current_month_transactions.bank_accounts[account.account_id];
    } else if (account.type === 'card' && userData.current_month_transactions?.cards?.[account.card_id]) {
      transactions = userData.current_month_transactions.cards[account.card_id];
    }

    if (transactions.length > 0) {
      const totalIncoming = processTransactions(transactions, true, account.type === 'account');
      const totalOutgoing = processTransactions(transactions, false, account.type === 'account');

      ColumnsChart = [
        { name: t("col_chart_incomes"), value: totalIncoming },
        { name: t("col_chart_expenses"), value: totalOutgoing },
      ];
    }

    return (
      <>
        <FormControl variant="outlined" style={{ marginRight: '10px', width: '450px', marginBottom: '30px' }}>
          <InputLabel
            id="account-select-label"
            className="transactions_input-label"
            shrink={true}
          >
            {t('select_account_bankaccount_or_card')}
          </InputLabel>
          <Select
            labelId="account-select-label"
            id="account-select"
            value={account}
            label={t('select_account')}
            onChange={handleChange}
            color="primary"
            IconComponent={ListIcon}
            className="transactions_select"
            renderValue={(selected) => (
              <Box display="flex" alignItems="center">
                {selected && selected.type === 'account' ? <AccountBalanceIcon /> : <CreditCardIcon />}
                <Typography variant="body2" noWrap style={{ marginLeft: '8px' }}>
                  {selected ? selected.name : ''}
                </Typography>
              </Box>
            )}
          >
            {userAccounts.map((item) => (
              <MenuItem key={item.card_id || item.account_id} value={item}>
                <ListItemIcon>
                  {item.type === 'account' ? <AccountBalanceIcon /> : <CreditCardIcon />}
                </ListItemIcon>
                <ListItemText primary={item.name} />
              </MenuItem>
            ))}
          </Select>

        </FormControl>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={ColumnsChart} margin={{ top: 20, right: 20, left: 0, bottom: 20 }}>
            <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" vertical={false} />
            <XAxis dataKey="name" axisLine={false} tickLine={false} tick={{ fill: '#657583' }} />
            <YAxis axisLine={false} tickLine={false} tick={{ fill: '#657583' }} tickFormatter={(value) => number_to_italian_nosymbol(value)} />
            <Tooltip
              formatter={(value, name) => [number_to_italian_nosymbol(value), t('variation_balance')]}
              labelStyle={{ display: 'none' }}
            />

            <Bar dataKey="value" barSize={60}>
              {ColumnsChart.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={entry.name === t('col_chart_incomes') ? '#36ae54' : '#e34542'}
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </>
    );
  }
}

function VariationTrendChart({ userAccounts, userData }) {
  const [account, setAccount] = useState('');
  const [accountType, setAccountType] = useState(null);
  const [variationBalanceChart, setVariationBalanceChart] = useState([]);

  const handleChange = (event) => {
    const selected = event.target.value;
    setAccountType(selected.type);
    setAccount(selected); // Store the entire selected object
  };

  useEffect(() => {
    if (!account && userAccounts.length > 0) {
      const firstCard = userAccounts.find(acc => acc.type === 'card');
      const firstAccount = userAccounts.find(acc => acc.type === 'account');
      const initialAccount = firstAccount; // firstCard || firstAccount;
      if (initialAccount) {
        setAccount(initialAccount);
        setAccountType(initialAccount.type);
      }
    }

    if (userData && account) {
      let dailyBalances = {};
      let transactions = [];

      if (
        userData &&
        userData.current_month_transactions &&
        userData.current_month_transactions.bank_accounts &&
        userData.current_month_transactions.bank_accounts.hasOwnProperty(account.account_id)
      ) {
        transactions = userData.current_month_transactions.bank_accounts[account.account_id];
      } else if (
        userData &&
        userData.current_month_transactions &&
        userData.current_month_transactions.cards &&
        userData.current_month_transactions.cards.hasOwnProperty(account.card_id)
      ) {
        transactions = userData.current_month_transactions.cards[account.card_id];
      } else {
        transactions = Array();
      }

      // Get the current date
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();
      const today = currentDate.getDate();
      const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

      // Initialize all days from the 1st to today with a balance of 0
      for (let day = 1; day <= today; day++) {
        // Create a date object for the current local date
        const date = new Date(currentYear, currentMonth, day);

        // Convert it to YYYYMMDD format without changing the time zone
        const dateKey = date.getFullYear().toString() +
          ('0' + (date.getMonth() + 1)).slice(-2) +
          ('0' + date.getDate()).slice(-2);
        dailyBalances[dateKey] = 0;
      }

      transactions.forEach((transaction) => {
        const transactionDate = new Date(
          transaction.transaction_created_at ||
          transaction.transaction_date_time
        );
        const dateKey = transactionDate
          .toISOString()
          .split("T")[0]
          .replace(/-/g, "");
        if (
          transaction.status !== "REJECTED" &&
          !isNOOPTransaction(transaction)
        ) {
          dailyBalances[dateKey] = isCreditTransaction(transaction)
            ? (dailyBalances[dateKey] || 0) + transaction.amount
            : (dailyBalances[dateKey] || 0) - transaction.amount;
        }
      });

      const sortedDates = Object.keys(dailyBalances).sort((a, b) => new Date(a) - new Date(b));
      let accumulatedBalance = 0;

      const chartData = sortedDates.map(date => {
        accumulatedBalance += dailyBalances[date];
        // Format the date to show only the day of the month
        const dayOfMonth = new Date(date.substring(0, 4), date.substring(4, 6) - 1, date.substring(6, 8)).getDate();

        return {
          name: dayOfMonth, // Only the day of the month
          value: accumulatedBalance
        };
      });

      setVariationBalanceChart(chartData);
    }
  }, [account, accountType, userAccounts, userData]);

  return (
    <>
      <FormControl variant="outlined" style={{ marginRight: '10px', width: '450px', marginBottom: '20px' }}>
        <InputLabel
          id="account-select-label"
          className="transactions_input-label"
          shrink={true}
        >
          {t('select_account_bankaccount_or_card')}
        </InputLabel>
        <Select
          labelId="account-select-label"
          id="account-select"
          value={account}
          label={t('select_account')}
          onChange={handleChange}
          color="primary"
          IconComponent={ListIcon}
          className="transactions_select"
          renderValue={(selected) => (
            <Box display="flex" alignItems="center">
              {selected && selected.type === 'account' ? <AccountBalanceIcon /> : <CreditCardIcon />}
              <Typography variant="body2" noWrap style={{ marginLeft: '8px' }}>
                {selected ? selected.name : ''}
              </Typography>
            </Box>
          )}
        >
          {userAccounts.map((item) => (
            <MenuItem key={item.card_id || item.account_id} value={item}>
              <ListItemIcon>
                {item.type === 'account' ? <AccountBalanceIcon /> : <CreditCardIcon />}
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </MenuItem>
          ))}
        </Select>

      </FormControl>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          data={variationBalanceChart}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }} // Adjust the left margin to provide more space for Y-axis labels
        >
          <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" vertical={false} />
          <XAxis
            dataKey="name"
            axisLine={false}
            tickLine={false}
            tick={{ fill: '#657583' }}
            tickMargin={10}
            height={60} // Increased height for the XAxis area to accommodate the month label
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            tick={{ fill: '#657583' }}
            tickFormatter={(value) => number_to_italian_currency_nocents(value)}
            tickMargin={10}
            width={80} // You can also adjust the width of the YAxis to ensure labels fit
          />
          <Line type="monotone" dataKey="value" stroke="#333333" strokeWidth={3} dot={{ r: 1 }} />
          <Tooltip
            formatter={(value, name, props) => {
              // Round the value to 2 decimal places
              const roundedValue = number_to_italian_currency_nocents(value);

              // Format the rounded value with '€' and translate 'variation_balance'
              return [`${roundedValue}`, t('variation_balance')];
            }}
            labelFormatter={(label) => {
              const currentDate = new Date();
              const monthNumber = currentDate.getMonth() + 1; // Months are 0-indexed in JavaScript
              return `${t('chart_balance_day')}: ${label}/${monthNumber}`;
            }}
            cursor={{ strokeDasharray: '3 3' }}
          />

        </LineChart>
      </ResponsiveContainer>
    </>
  )
}

function TransactionsTable({ userAccounts, userData, page, rowsPerPage, setPage, setRowsPerPage, setPageCount, pageData }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'date', direction: 'desc' });
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [account, setAccount] = useState('');
  const [accountType, setAccountType] = useState(null);
  const [transactions, setTransactions] = useState([]);

  const handleChange = (event) => {
    const selected = event.target.value;
    setAccountType(selected.type);
    setAccount(selected); // Store the entire selected object
  };

  // select default on opening
  useEffect(() => {
    if (!account && userAccounts.length > 0) {
      const firstCard = userAccounts.find(acc => acc.type === 'card');
      const firstAccount = userAccounts.find(acc => acc.type === 'account');
      const initialAccount = firstAccount; // firstCard || firstAccount;
      if (initialAccount) {
        setAccount(initialAccount);
        setAccountType(initialAccount.type);
      }
    }
  }, [userAccounts]);

  useEffect(() => {
    if (account && userData && userData.current_month_transactions) {
      setPage(0)
      let newTransactions = [];

      // Process transactions based on the account type
      if (accountType === 'account') {


        let accountTransactions = [];

        if (
          userData &&
          userData.current_month_transactions &&
          userData.current_month_transactions.bank_accounts &&
          userData.current_month_transactions.bank_accounts.hasOwnProperty(account.account_id)
        ) {
          accountTransactions = userData.current_month_transactions.bank_accounts[account.account_id];
        } else {
          accountTransactions = Array();
        }

        if (accountTransactions) {
          newTransactions = accountTransactions.map(tx => {
            const formatter = new Intl.NumberFormat('it-IT', {
              style: 'currency',
              currency: tx.currency,
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
            const rawAmount = tx.transaction_type === 'PURCHASE' ? -Math.abs(tx.amount) : tx.amount;
            const formattedAmount = formatter.format(rawAmount);
            const lastFourDigits = isTopUpCardTransaction(tx)
              ? pageData.user_accounts.card_accounts.find(
                (e) => e.account_number === tx.account_to
              )?.card_last_four_digits || ""
              : "";

            const transactionDescription = `${t(transactionTypeKey(tx))} ${lastFourDigits}`;
            return {
              date: tx.transaction_date_time,
              description: transactionDescription,
              amount: formattedAmount,
              status: tx.status,
              transaction_type: tx.transaction_type,
              direction: tx.direction
            };
          });
        }
      } else if (accountType === 'card') {


        let cardTransactions = [];

        if (
          userData &&
          userData.current_month_transactions &&
          userData.current_month_transactions.cards &&
          userData.current_month_transactions.cards.hasOwnProperty(account.card_id)
        ) {
          cardTransactions = userData.current_month_transactions.cards[account.card_id];
        } else {
          cardTransactions = Array();
        }

        if (cardTransactions) {
          newTransactions = cardTransactions.map(tx => {
            const rawAmount = tx.transaction_type === 'PURCHASE' ? -Math.abs(tx.amount) : tx.amount;
            const formattedAmount = number_to_italian_currency(rawAmount);
            return {
              date: tx.transaction_created_at ? tx.transaction_created_at : '',
              description: tx.description ? tx.description : tx.merchant_name ? t(tx.transaction_type) + ' ' + tx.merchant_name : t(tx.transaction_type),
              amount: formattedAmount,
              transaction_id: tx.transaction_id,
              status: tx.status,
              transaction_type: tx.transaction_type,
              direction: tx.direction,
              transaction_financial_status: tx.transaction_financial_status
            };
          });
        }
      }

      // Apply sorting to the newTransactions
      const sortedTransactions = newTransactions.sort((a, b) => {
        if (sortConfig.key === 'amount') {
          const amountA = italian_currency_to_number_with_negative_amounts(a.amount);
          const amountB = italian_currency_to_number_with_negative_amounts(b.amount);
          return amountA < amountB ? (sortConfig.direction === 'asc' ? -1 : 1) : amountA > amountB ? (sortConfig.direction === 'asc' ? 1 : -1) : 0;
        } else if (sortConfig.key === 'date') {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateA < dateB ? (sortConfig.direction === 'asc' ? -1 : 1) : dateA > dateB ? (sortConfig.direction === 'asc' ? 1 : -1) : 0;
        } else if (sortConfig.key === 'description') {
          return a.description < b.description ? (sortConfig.direction === 'asc' ? -1 : 1) : a.description > b.description ? (sortConfig.direction === 'asc' ? 1 : -1) : 0;
        }
        return 0;
      });

      // Apply filtering to the sortedTransactions
      const filteredTransactions = sortedTransactions.filter(transaction =>
        transaction.description.toLowerCase().includes(searchTerm.toLowerCase())
      );

      setTransactions(newTransactions); // Update the transactions state
      setFilteredTransactions(filteredTransactions); // Update the filtered transactions state
      setPageCount(Math.ceil(filteredTransactions.length / rowsPerPage));
    }
  }, [userAccounts, userData, account, accountType, sortConfig, searchTerm, rowsPerPage, setPageCount]);

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const renderSortLabel = (key, label) => (
    <TableSortLabel
      active={sortConfig.key === key}
      direction={sortConfig.key === key ? sortConfig.direction : 'asc'}
      onClick={() => requestSort(key)}
    >
      {label}
    </TableSortLabel>
  );

  return (
    <div>
      <FormControl variant="outlined" style={{ marginRight: '10px', width: '450px', marginBottom: '30px' }}>
        <InputLabel
          id="account-select-label"
          className="transactions_input-label"
          shrink={true}
        >
          {t('select_account_bankaccount_or_card')}
        </InputLabel>
        <Select
          labelId="account-select-label"
          id="account-select"
          value={account}
          label={t('select_account')}
          onChange={handleChange}
          color="primary"
          IconComponent={ListIcon}
          className="transactions_select"
          renderValue={(selected) => (
            <Box display="flex" alignItems="center">
              {selected && selected.type === 'account' ? <AccountBalanceIcon /> : <CreditCardIcon />}
              <Typography variant="body2" noWrap style={{ marginLeft: '8px' }}>
                {selected ? selected.name : ''}
              </Typography>
            </Box>
          )}
        >
          {userAccounts.map((item) => (
            <MenuItem key={item.card_id || item.account_id} value={item}>
              <ListItemIcon>
                {item.type === 'account' ? <AccountBalanceIcon /> : <CreditCardIcon />}
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </MenuItem>
          ))}
        </Select>

      </FormControl>

      <TextField
        name="search_filter_unique"
        id="search_filter_unique_id"
        label={t('search_filter')}
        variant="outlined"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        autoComplete="no-autofill"
      />
      <Table style={{ marginTop: '20px' }}>
        <TableHead>
          <TableRow>
            <TableCell key="date">{renderSortLabel('date', t('date'))}</TableCell>
            <TableCell key="description">{renderSortLabel('description', t('description'))}</TableCell>
            <TableCell key="amount" align="right">{renderSortLabel('amount', t('amount'))}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredTransactions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((transaction) => {
            // console.log(transaction);
            return (
              <TableRow key={transaction.transaction_id}>
                <TableCell className="table_body">
                  {format(new Date(transaction.date), "dd/MM/yyyy HH:mm")}
                </TableCell>
                <TableCell className="table_body">
                  {transaction.description}
                </TableCell>
                {transaction.status === "REJECTED" ||
                  isNOOPTransaction(transaction) || transaction.transaction_financial_status === "ON_HOLD" ? (
                  <TableCell className="table_body" align="right">
                    {transaction.status === "REJECTED"
                      ? t("cancelled")
                      : transaction.amount}
                  </TableCell>
                ) : (
                  <TableCell className={`table_body ${isCreditTransaction(transaction) ? "inward_money" : "outward_money"}`} align="right">
                    {transaction.amount}
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <TablePagination
        component="div"
        count={filteredTransactions.length}
        page={page}
        onPageChange={(event, newPage) => setPage(newPage)}
        rowsPerPage={rowsPerPage}
        labelRowsPerPage={t('rows_per_page')}
        labelDisplayedRows={
          ({ from, to, count }) => {
            return '' + from + '-' + to + ` ${t('of')} ` + count
          }
        }
        onRowsPerPageChange={(event) => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPage(0);
        }}
      />
    </div>
  );
}

function CardBalance({ accountBalances, accountId }) {
  const accountBalance = accountBalances.find((e) => e.id === accountId);
  if (accountBalance) {
    return (
      <div>
        {`${t("card_balance")}: ${number_to_italian_currency(
          accountBalance.balance.available_amount ??
          accountBalance.balance.amount
        )}`}
      </div>
    );
  }
  return <div></div>;
}

function Page_Home({ onDataChange, ...props }) {
  const { update } = useIntercom();
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const isMobile = useMediaQuery('(max-width:768px)');
  const [transactions, setTransactions] = useState([]);
  const [bankAccountID, setbankAccountID] = useState(null);
  const [bankAccountBalance, setBankAccountBalance] = useState(null);
  const [isBalanceApiMaintain, setBalanceApiMaintain] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalTransactionCount, setTotalTransactionCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [userAccounts, setUserAccounts] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [selectedCard, setSelectedCard] = useState(null);
  const [accountBalances, setAccountBalances] = useState(null);

  // Inside the get_page_data function, after fetching the data
  const processAccountsData = (userData) => {
    const cardAccounts = userData.user_accounts.card_accounts
      .filter(account => account.card_last_four_digits !== null)  // Filter out accounts with null last four digits
      .map(account => ({
        name: `${t('card_label')} **** **** **** ${account.card_last_four_digits}`, // Masked PAN
        card_id: `${account.card_id}`,
        type: 'card'
      }));

    const currentAccounts = userData.user_accounts.current_accounts.map(account => ({
      name: `${t('bank_accounts')} ${account.account_number}`,
      account_id: `${account.account_id}`,
      type: 'account'
    }));


    const allAccounts = [...currentAccounts, ...cardAccounts];
    setUserAccounts(allAccounts);
  };

  const get_page_data = async () => {
    const token = getCookieValue('firebaseToken');

    setIsLoading(true);
    try {
      const apidata = await fetchPageData('home');
      if (apidata.status === 'error') {
        if (apidata.fdt_error_code === 'TOKEN_EXPIRED') {
          navigate('/login?action=clean-cookie&reason=session_expired');
          return;
        }
        alert(t('error_getting_data_from_server'));
        return;
      }

      const data = apidata.data;

      const language = getCookieValue("language") === "en" ? "en" : "it";
      if (data.settings?.general?.user_language && data.settings?.general?.user_language !== language) {
        changeLanguage(data.settings?.general?.user_language);
      }
      setTimeout(() => {
        update({ email: data.details.email, name: data.details.name });
      }, 5000);
      processAccountsData(data);
      if (onDataChange) {
        onDataChange(data);
      }
      if (data && data.user_accounts.current_accounts[0].account_id) {
        setbankAccountID(data.user_accounts.current_accounts[0].account_id);
      }
      setUserData(data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };

  const getAccountBalances = async () => {
    try {
      const data = await fetchAllAccountBalances();
      setAccountBalances(data);
    } catch (error) {
      console.error("Error fetching all account balances:", error);
      const isMaintain = error.message === "UNLIMIT_PLANNED_MAINTENANCE";
      if (isMaintain !== isBalanceApiMaintain) {
        setBalanceApiMaintain(isMaintain);
      }
    }
  };

  useEffect(() => {
    get_page_data();
    getAccountBalances();
  }, []);

  useEffect(() => {
    const formatter = new Intl.NumberFormat('it-IT', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    async function updateBankAccountBalance() {
      try {
        const balance = await fetchBankAccountBalance(bankAccountID, getCookieValue);
        setBankAccountBalance(formatter.format(balance));
      } catch (error) {
        const isMaintain = error.message === "UNLIMIT_PLANNED_MAINTENANCE";
        if (isMaintain !== isBalanceApiMaintain) {
          setBalanceApiMaintain(isMaintain);
        }
      }
    }

    if (bankAccountID) {
      updateBankAccountBalance();
    }
  }, [bankAccountID, getCookieValue]);

  const StartBankTransfer = () => {
    navigate('/bank-transfers/make-payment/');
  };

  const UpdateYourDetails = () => {
    navigate('/profile/');
  };


  function SlideTransition(props) {
    return <Slide {...props} direction="left" />;
  }

  const currentLanguage = getCookieValue("language");
  const now = new Date();
  const dateString = now.toLocaleString(currentLanguage, {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  }).replace(',', ' alle');

  if (isLoading) {
    return <Loading></Loading>;
  }

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
  }

  const handleOpenEditModal = (card) => {
    setSelectedCard(card);
    setIsEditModalOpen(true);
  };

  const slider_settings = {
    dots: false,
    infinite: false, // Disable infinite looping
    speed: 500,
    slidesToShow: 1,
    dots: true,
    customPaging: (i) => (
      <div
        style={{
          width: '5px',
          height: '5px',
          borderRadius: '50%',
          backgroundColor: i === currentSlide ? '#E09F3E' : '#f0f0f0', // Active dot in red
          padding: '3px',
          margin: '10px 5px',
          cursor: 'pointer',
        }}
      ></div>
    ),
    dotsClass: 'slick-dots custom-dot-class',
    beforeChange: (current, next) => setCurrentSlide(next), // Update current slide index
  };

  const handleCopy = () => {
    // Copy account number logic
    navigator.clipboard.writeText(userData.user_accounts.current_accounts[0].account_number);

    // Show Snackbar
    setOpenSnackbar(true);

    // Automatically close Snackbar after 2 seconds
    setTimeout(() => {
      setOpenSnackbar(false);
    }, 2000);
  };

  const hasNoCard =
    userData?.user_accounts.card_accounts.filter(
      (card) => card.card_status !== "UNKNOWN"
    ).length === 0;

  return (
    <div
      style={{ marginLeft: "auto", marginRight: "auto", maxWidth: "1500px" }}
    >
      {isEditModalOpen && (
        <Modal_StartCardEdit
          isOpen={isEditModalOpen}
          onClose={handleCloseEditModal}
          card_accounts={userData.user_accounts.card_accounts}
          cardId={selectedCard.card_id}
          card_current_status={selectedCard.current_status}
          closeModal={handleCloseEditModal}
        />
      )}
      <ThemeProvider theme={theme}>
        <Grid
          container
          spacing={3}
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "10px !important",
          }}
        >
          <Grid item xs={12}></Grid>
          <Grid
            item
            xs={12}
            sm={isMobile ? 12 : 6}
            style={{ minWidth: isMobile ? "400px" : "500px" }}
          >
            <Card
              style={{
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                ...cardStyles_bg,
              }}
            >
              <CardContent
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <img
                    src={logopng}
                    alt="Company Logo"
                    style={{
                      width: "40px",
                      height: "68px",
                      marginRight: "15px",
                    }}
                  />
                  <Typography
                    variant="body1"
                    style={{ ...textStyle_big_account }}
                  >
                    {t("welcome")} {userData.details.name ?? ""}{" "}
                    {userData.details.last_name ?? ""}
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    marginBottom: "20px",
                  }}
                >
                  {!isBalanceApiMaintain && (
                    <Typography
                      variant="h4"
                      style={{ fontWeight: "bold", ...textStyle_big_dark }}
                    >
                      {t("account_balance")}:
                    </Typography>
                  )}
                  {!isBalanceApiMaintain && (
                    <Typography
                      variant="h4"
                      style={{ fontWeight: "bold", ...textStyle_big_dark }}
                    >
                      {bankAccountBalance === null ? (
                        <CircularProgress size={14} />
                      ) : (
                        bankAccountBalance
                      )}
                    </Typography>
                  )}
                  {isBalanceApiMaintain && (
                    <Typography
                      variant="body2"
                      style={{ ...textStyle_small_dark }}
                    >
                      {t("error_message_balance_api_maintain")}
                    </Typography>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    marginBottom: "20px",
                  }}
                >
                  <Typography
                    variant="body2"
                    style={{ ...textStyle_small_dark }}
                  >
                    {t("account_number")}:&nbsp;
                  </Typography>
                  <div>
                    <Typography
                      variant="body2"
                      style={{ ...textStyle_small_dark, cursor: "pointer" }}
                      onClick={handleCopy}
                    >
                      {
                        userData.user_accounts.current_accounts[0]
                          .account_number
                      }
                      <FontAwesomeIcon
                        icon={faCopy}
                        onClick={handleCopy}
                        style={{ marginLeft: "10px" }}
                      />
                    </Typography>

                    <Snackbar
                      open={openSnackbar}
                      onClose={() => setOpenSnackbar(false)}
                      TransitionComponent={SlideTransition}
                      autoHideDuration={2000}
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    >
                      <SnackbarContent
                        message={t("iban_copied")}
                        style={{ backgroundColor: "#333333" }}
                      />
                    </Snackbar>
                  </div>
                </div>
                <Typography variant="body2" style={{ ...textStyle_small_dark }}>
                  {t("last_updated_info")} {dateString}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Card
              style={{
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                ...cardStyles_bg,
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  left: "50%",
                  transform: "translateX(-50%)",
                  top: "16px",
                  zIndex: 56,
                  color: "#ffffff",
                  fontWeight: "bold",
                }}
              >
                {!hasNoCard ? (
                  isBalanceApiMaintain || (userData?.user_accounts.card_accounts[currentSlide]?.account_id ?? "") === "INACTIVE" ? (
                    <div />
                  ) : accountBalances ? (
                    <CardBalance
                      accountBalances={accountBalances}
                      accountId={
                        userData?.user_accounts.card_accounts[currentSlide]
                          ?.account_id ?? ""
                      }
                    />
                  ) : (
                    <CircularProgress size="1rem" />
                  )
                ) : (
                  <div />
                )}
              </div>
              {isBusinessAccount() && <Box sx={{ width: "400px" }}>
              <img
                      style={{
                        width: "calc(100% - 20px)",
                        marginRight: "10px",
                        marginLeft: "10px",
                        boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.15)",
                        borderRadius: "17px",                     
                      }}
                      src={BusinessGrayCard}
                      alt={`order new card`}
                    />
                </Box>}
              {!isBusinessAccount() && <Box sx={{ width: "400px" }}>
                {hasNoCard && (
                  // If no cards are left after filtering, you can perform some actions here or render something specific
                  <Box sx={{ cursor: "pointer", position: "relative" }}>
                    <img
                      style={{
                        width: "calc(100% - 20px)",
                        marginRight: "10px",
                        marginLeft: "10px",
                        cursor: "pointer",
                        boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.15)",
                        borderRadius: "17px",
                        filter: "blur(2px)",
                      }}
                      src={SliderCardWhite}
                      alt={`order new card`}
                    />
                    <Button
                      onClick={() => navigate("/cards/order")}
                      variant="contained"
                      color="primary"
                      sx={{
                        width: "75%",
                        position: "absolute",
                        bottom: "40%",
                        left: "50%",
                        transform: "translateX(-50%)",
                        zIndex: 55,
                      }}
                    >
                      {t("order_your_first_card")}
                    </Button>
                  </Box>
                )}
                <Slider {...slider_settings}>
                  {userData?.user_accounts.card_accounts
                    .filter((card) => card.card_status !== "UNKNOWN")
                    .map(
                      (card, index) => (
                        //  console.log(card),
                        (
                          <Box
                            key={index}
                            sx={{ cursor: "pointer", position: "relative" }}
                          >
                            {card.card_type === "VIRTUAL" ? (
                              <div
                                style={{
                                  color: "#333333",
                                  position: "absolute",
                                  top: "95px",
                                  left: "130px",
                                  zIndex: 10,
                                  fontSize: "1rem",
                                  width: "150px",
                                  textAlign: "center",
                                  lineHeight: "26px",
                                  height: "30px",
                                }}
                              >
                                {t("BAAS_CARD_VIRTUAL_title")}{" "}
                                {/* Assuming t is a translation function */}
                              </div>
                            ) : (
                              <div
                                style={{
                                  color: "#333333",
                                  position: "absolute",
                                  top: "95px",
                                  left: "130px",
                                  zIndex: 10,
                                  fontSize: "1rem",
                                  width: "150px",
                                  textAlign: "center",
                                  lineHeight: "26px",
                                  height: "30px",
                                }}
                              >
                                {t("BAAS_CARD_PLASTIC_title")}{" "}
                                {/* Assuming t is a translation function */}
                              </div>
                            )}

                            <div
                              style={{
                                color: `${card.current_status === "ACTIVE"
                                    ? "#3dc690"
                                    : card.current_status === "INACTIVE" ||
                                      card.current_status === "BLOCKED" ||
                                      card.current_status === "SUSPEND" ||
                                      card.current_status === "TERMINATED"
                                      ? "#e34542"
                                      : "#aaaaaa"
                                  }`,
                                position: "absolute",
                                bottom: "25px",
                                left: "20px",
                                zIndex: 10,
                                fontSize: "1rem",
                                width: "100px",
                                textAlign: "center",
                                lineHeight: "26px",
                              }}
                            >
                              {t(`card_${card.current_status}`)}{" "}
                            </div>

                            <img
                              style={{
                                width: "calc(100% - 20px)",
                                marginRight: "10px",
                                marginLeft: "10px",
                                cursor: "pointer",
                                boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.15)",
                                borderRadius: "17px",
                              }}
                              src={
                                card.card_color === "blue"
                                  ? SliderCardBlue
                                  : SliderCardWhite
                              }
                              alt={`card ${card.card_color}`}
                            />

                            <div
                              style={{
                                position: "absolute",
                                bottom: "70px",
                                left: "50%",
                                transform: "translateX(-50%)",
                                zIndex: 55,
                                color: "#333333",
                                // Add additional styling as needed here
                              }}
                            >
                              {card.card_last_four_digits
                                ? "**** **** **** " + card.card_last_four_digits
                                : "**** **** **** ****"}
                            </div>

                            <Button
                              onClick={() => handleOpenEditModal(card)}
                              variant="contained"
                              color="primary"
                              disabled={
                                card.current_status !== "ACTIVE" &&
                                card.current_status !== "BLOCKED" &&
                                card.current_status !== "INACTIVE"
                              }
                              sx={{
                                position: "absolute",
                                bottom: "20px",
                                left: "50%",
                                transform: "translateX(-50%)",
                                zIndex: 55,
                              }}
                            >
                              {t("show_data")}
                            </Button>
                          </Box>
                        )
                      )
                    )}
                </Slider>
              </Box>}
            </Card>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            style={{
              minWidth: isMobile ? "300px" : "500px",
            }}
          >
            <Card style={{ boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)" }}>
              <CardContent>
                <Typography
                  variant="body1"
                  style={{ ...textStyle_small_light, ...blue_title }}
                >
                  {t("quick_actions")}:
                </Typography>
                <Grid container spacing={2} justifyContent="flex-start">
                  <Grid
                    item
                    xs={isMobile ? 6 : 4}
                    style={{
                      display: "flex",
                      justifyContent: isMobile ? "flex-start" : "center",
                    }}
                  >
                    <Button
                      style={{
                        height: "60px",
                        width: "100%",
                      }}
                      variant="contained"
                      color="primary"
                      startIcon={<BankIcon />}
                      fullWidth
                      onClick={StartBankTransfer}
                    >
                      {t("bank_transfer_sepa")}
                    </Button>
                  </Grid>
                  {!isBusinessAccount() && <Grid
                    item
                    xs={isMobile ? 6 : 4}
                    style={{
                      display: "flex",
                      justifyContent: isMobile ? "flex-start" : "center",
                    }}
                  >
                    <Button
                      style={{
                        height: "60px",
                        width: "100%",
                      }}
                      variant="contained"
                      color="primary"
                      startIcon={<RechargeIcon />}
                      fullWidth
                      onClick={() => navigate("/cards/recharge/select-card")}
                    >
                      {t("recharge_your_card")}
                    </Button>
                  </Grid>}
                  {!isBusinessAccount() && <Grid
                    item
                    xs={isMobile ? 6 : 4}
                    style={{
                      display: "flex",
                      justifyContent: isMobile ? "flex-start" : "center",
                    }}
                  >
                    <Button
                      style={{
                        height: "60px",
                        width: "100%",
                      }}
                      variant="contained"
                      color="primary"
                      startIcon={<RechargeIcon />}
                      fullWidth
                      onClick={() => navigate("/cards/withdraw/select-card")}
                    >
                      {t("recharge_account_from_card")}
                    </Button>
                  </Grid>}
                  <Grid
                    item
                    xs={isMobile ? 6 : 4}
                    style={{
                      display: "flex",
                      justifyContent: isMobile ? "flex-start" : "center",
                    }}
                  >
                    <Button
                      style={{
                        height: "60px",
                        width: "100%",
                      }}
                      variant="contained"
                      color="primary"
                      startIcon={<UpdateIcon />}
                      fullWidth
                      onClick={UpdateYourDetails}
                    >
                      {t("update_your_details")}
                    </Button>
                  </Grid>
                  {!isBusinessAccount() && <Grid
                    item
                    xs={isMobile ? 6 : 4}
                    style={{
                      display: "flex",
                      justifyContent: isMobile ? "flex-start" : "center",
                    }}
                  >
                    <Button
                      style={{
                        height: "60px",
                        width: "100%",
                      }}
                      variant="contained"
                      color="primary"
                      startIcon={<CardIcon />}
                      fullWidth
                      onClick={() => navigate("/cards/order")}
                    >
                      {t("order_a_new_card")}
                    </Button>
                  </Grid>}
                  {!isBusinessAccount() && <Grid
                    item
                    xs={isMobile ? 6 : 4}
                    style={{
                      display: "flex",
                      justifyContent: isMobile ? "flex-start" : "center",
                    }}
                  >
                    <Button
                      style={{
                        height: "60px",
                        width: "100%",
                      }}
                      variant="contained"
                      color="primary"
                      startIcon={<CardIcon />}
                      fullWidth
                      onClick={() => navigate("/services/mobile")}
                    >
                      {t("phone_top_up")}
                    </Button>
                  </Grid>}
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            style={{ minWidth: isMobile ? "300px" : "500px" }}
          >
            <Card
              style={{
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                padding: "10px",
                height: "450px",
              }}
            >
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body1"
                    style={{ ...textStyle_small_light, ...blue_title }}
                  >
                    {t("this_month_balance_change")}
                  </Typography>
                  <ShowChartIcon color="primary" />
                </div>
                <VariationTrendChart
                  userAccounts={userAccounts}
                  userData={userData}
                />
              </CardContent>
            </Card>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            style={{ minWidth: isMobile ? "300px" : "500px" }}
          >
            <Card
              style={{
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                padding: "10px",
                height: "450px",
              }}
            >
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body1"
                    style={{ ...textStyle_small_light, ...blue_title }}
                  >
                    {t("cards_home_chart")}
                  </Typography>
                  <BarChartIcon color="primary" />
                </div>
                <VariationColumnChart
                  userAccounts={userAccounts}
                  userData={userData}
                />
              </CardContent>
            </Card>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            style={{
              minWidth: isMobile ? "300px" : "500px",
              marginBottom: "20px",
            }}
          >
            <Card
              style={{
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                padding: "10px",
                minHeight: "380px",
              }}
            >
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body1"
                    style={{ ...textStyle_small_light, ...blue_title }}
                  >
                    {t("latest_transactions_bankaccount_and_cards")}
                  </Typography>
                  <ListIcon color="primary" />
                </div>
                <TransactionsTable
                  userData={userData}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  setPage={setPage}
                  setRowsPerPage={setRowsPerPage}
                  setPageCount={setPageCount}
                  userAccounts={userAccounts}
                  pageData={userData}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </ThemeProvider>
    </div>
  );
}

export default Page_Home;