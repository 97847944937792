import { Box, Button, ThemeProvider, Typography, Link } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCookieValue } from "./App";
import BusinessIntroduction from "./components/business_introduction";
import "./css/Unauthenticated.css";
import logopng from "./pics/logo.png";
import { theme } from "./ReactStyles";
import { getBusinessAccounts } from "./Util_API_calls";
import { t } from "./Util_format";

function New_account() {
  const [isShowBusinessIntroduction, setShowBusinessIntroduction] =
    useState(false);
  const navigate = useNavigate();
  const initiatedKybAccounts = useMemo(
    () => getBusinessAccounts().filter((e) => e.statusId !== 100),
    []
  );

  useEffect(() => {
    const type = window.location.pathname.split("/").pop();
    if (type === "business") {
      setShowBusinessIntroduction(true);
    } else if (getCookieValue("login_otp_completed") === "true") {
      navigate("/home");
    } else if (getCookieValue("user_logged_in") !== "true") {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <ThemeProvider theme={theme}>
      <div id="new_account">
        <div className="container">
          <div className="unauthenticated_left_container">
            <img src={logopng} alt={t("logo")} className="login_logo_desktop" />
            <div className="overlayImage"></div>
          </div>
          {!isShowBusinessIntroduction ? (
            <div className="unauthenticated_right_container">
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                <div style={{ width: "100%" }}>
                  <img
                    src={logopng}
                    alt={t("logo")}
                    className="login_logo_mobile"
                  />
                </div>
                {t("Quale conto vuoi aprire?")}
              </Typography>
              <Box sx={{ mt: 3 }} />
              <Button
                variant="contained"
                color="primary"
                className="unauthenticated_button"
                onClick={() => {
                  setShowBusinessIntroduction(true);
                }}
              >
                {t("Conto Aziendale")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                className="unauthenticated_button"
                onClick={() => {
                  navigate("/kyc");
                }}
              >
                {t("Conto Privato")}
              </Button>

              {initiatedKybAccounts.length > 0 && (
                <Typography sx={{ mx: 1 }}>
                  {t("Oppure riprendi da dove hai lasciato")}
                </Typography>
              )}
              {initiatedKybAccounts.map((e) => (
                <Button
                  id={e.business_id}
                  variant="contained"
                  color="primary"
                  className="unauthenticated_button"
                  onClick={() => {
                    navigate(`/kyb/${e.business_id}`);
                  }}
                >
                  {`${t("Avviato KYB")} ${e.label}`}
                </Button>
              ))}
              <Link
                href="/login?action=clean-cookie"
                variant="body2"
                className="returnToLogin"
              >
                {t("logout")}
              </Link>
            </div>
          ) : (
            <div className="unauthenticated_right_container">
              <BusinessIntroduction />
            </div>
          )}
        </div>
      </div>
    </ThemeProvider>
  );
}

export default New_account;
