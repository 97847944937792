import {
  Button,
  CircularProgress,
  Link,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCookieValue, setCookieValue } from "./App";
import "./css/Unauthenticated.css";
import logopng from "./pics/logo.png";
import { theme } from "./ReactStyles";
import { fetchPageData, get_api_url } from "./Util_API_calls";
import { t } from "./Util_format";

function Waiting_Push() {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState(false);
  const intervalRef = useRef();

  useEffect(() => {
    if (getCookieValue("otp_delivery_method") === "push") {
      intervalRef.current = setInterval(async () => {
        const apidata = await fetchPageData("home");
        if (apidata.status === "error") {
        } else {
          setCookieValue("login_otp_completed", "true");
          navigate("/home");
        }
      }, 5000);
    }
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [navigate]);

  useEffect(() => {
    const cookieRow = document.cookie
      .split("; ")
      .find((row) => row.startsWith("firebaseToken="));
    const cookieValue = cookieRow ? cookieRow.split("=")[1] : null;
    const loginOtpCompleted = getCookieValue("login_otp_completed");
    const KycCompleted = getCookieValue("kyc_completed");

    if (!cookieValue) {
      navigate("/login");
    } else {
      if (KycCompleted !== "true") {
        navigate('/new_account');
        return;
      }
      if (loginOtpCompleted === "true") {
        navigate("/home");
      }
    }
  }, [navigate]);

  const onForceSendSMS = useCallback(async () => {
    setIsProcessing(true);
    setError(null);

    const token = getCookieValue("firebaseToken");

    try {
      const API_URL = await get_api_url();
      const response = await fetch(`${API_URL}/api/auth/login-mfa/send-verification`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          device_type: "desktop",
          delivery_method: "sms",
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      setCookieValue("otp_delivery_method", "");
      navigate("/login_otp");
    } catch (error) {
      setError(error.message);
    } finally {
      setIsProcessing(false);
    }
  }, [navigate]);

  return (
    <ThemeProvider theme={theme}>
      <div id="page_login_otp">
        <div className="container">
          <div className="unauthenticated_left_container">
            <img src={logopng} alt={t("logo")} className="login_logo_desktop" />
            <div className="overlayImage"></div>
          </div>

          <div className="unauthenticated_right_container">
            <div style={{ width: "100%" }}>
              <img
                src={logopng}
                alt={t("logo")}
                className="login_logo_mobile"
              />
            </div>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              {t("waiting_to_approve_from_mobile")}
            </Typography>

            {error && (
              <div className="error-container">
                <div className="error-icon">!</div>
                <Typography className="error-text">{error}</Typography>
              </div>
            )}

            <div>
              <Typography variant="body1" gutterBottom>
                {t("prefer_to_send_a_sms_instead")}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                className={`unauthenticated_button ${
                  isProcessing ? "processingButton" : ""
                }`}
                disabled={isProcessing}
                onClick={onForceSendSMS}
              >
                {isProcessing ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  t("send_sms")
                )}
              </Button>
            </div>

            <Link
              href="/login?action=clean-cookie"
              variant="body2"
              className="returnToLogin"
              disabled={isProcessing}
            >
              {t("back_to_login")}
            </Link>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default Waiting_Push;
