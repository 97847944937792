import React, { useState, useEffect } from 'react';
import { Grid, Card, CardContent, Typography, Select, MenuItem, InputLabel, FormControl, ListItemIcon, ListItemText, Box, useMediaQuery, ThemeProvider, Table, TableBody, TableCell, TextField, TableHead, TableRow, TableSortLabel, } from '@mui/material';
import ListIcon from '@mui/icons-material/List';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DescriptionIcon from '@mui/icons-material/Description';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { format } from 'date-fns';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
import logopng from './pics/logo.png';
import pdf_icon from './pics/pdf_icon.svg';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { enGB, it } from 'date-fns/locale';
import { fetchPageData, fetchCardTransactions, fetchBankAccountTransactions } from './Util_API_calls'; // Adjust the path as necessary
import { t } from './Util_format';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { startOfMonth, endOfMonth } from 'date-fns';
import 'dayjs/locale/en-gb';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { number_to_italian_currency } from './Util_format';
import Modal_TransactionDetails from './Modal_TransactionDetails';
import { useNavigate } from 'react-router-dom';
import { textStyle_small_light, blue_title, iconStyle, menuItemStyle, theme } from './ReactStyles';
import { getCookieValue } from './App';
import { generatePDF } from './Util_generatePDF';
import { isCreditTransaction, isNOOPTransaction, isTopUpCardTransaction, transactionTypeKey } from './Util_app';

function TransactionsTable({ transactions, onRowClick, onPageChange, dateRange, searchTerm, account, pageData }) {
  const [sortConfig, setSortConfig] = useState({ key: 'date', direction: 'desc' });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Check if an account is selected and transactions is an array and not empty
  if (!account) {
    return (
      <Typography variant="body1" style={{ marginTop: '40px' }}>
        {t('please_select_the_account')}
      </Typography>
    );
  } else if (!Array.isArray(transactions) || transactions.length === 0) {
    return (
      <Typography variant="body1" style={{ marginTop: '40px' }}>
        {t('no_transactions_to_show')}
      </Typography>
    );
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return !isNaN(date.getTime()) ? format(date, 'dd/MM/yyyy hh:mm:ss', { locale: it }) : 'Invalid Date';
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    onPageChange(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedTransactions = [...transactions].sort((a, b) => {
    // Convert transaction date strings to Date objects
    // Use 'card_id' to determine if it is a credit card transaction
    const dateA = a.card_id ? new Date(a.transaction_created_at) : new Date(a.transaction_date_time);
    const dateB = b.card_id ? new Date(b.transaction_created_at) : new Date(b.transaction_date_time);
  
    if (sortConfig.key === 'date') {
      // Sort by date
      if (sortConfig.direction === 'asc') {
        return dateA - dateB; // Ascending order
      } else {
        return dateB - dateA; // Descending order
      }
    }
  
    // Sorting by other fields can be added here
    // Example: Sorting by amount
    if (sortConfig.key === 'amount') {
      if (parseFloat(a.amount) < parseFloat(b.amount)) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (parseFloat(a.amount) > parseFloat(b.amount)) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
    }
  
    // Add additional sorting logic for other fields if needed
  
    return 0; // Default return for equal items or unhandled sort keys
  });
  
  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const renderSortLabel = (key, label) => (
    <TableSortLabel
      active={sortConfig.key === key}
      direction={sortConfig.key === key ? sortConfig.direction : 'asc'}
      onClick={() => requestSort(key)}
      className='table_header'>
      {label}
    </TableSortLabel>
  );

  const filteredTransactions = sortedTransactions.filter(transaction => {
    // Determine the correct date field based on the transaction type
    const transactionDateField = 'bank_transfer_id' in transaction ? 'transaction_date_time' : 'transaction_created_at';
    const transactionDate = new Date(transaction[transactionDateField]);

    if (transactionDate < dateRange.from || transactionDate > dateRange.to) {
      return false;
    }

    // Determine the correct description field based on the transaction type
    const transactionDescription = 'bank_transfer_id' in transaction
      ? transaction.description?.toLowerCase() ?? ''
      : transaction.merchant_name ? (t(transaction.transaction_type) + ' @ ' + transaction.merchant_name).toLowerCase() : t(transaction.transaction_type).toLowerCase();

    return transactionDescription.includes(searchTerm.toLowerCase());
  });



  const displayedTransactions = filteredTransactions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <div>
      <Table style={{ marginTop: '20px' }}>
        <TableHead>
          <TableRow>
            <TableCell>{renderSortLabel('date', t('date'))}</TableCell>
            <TableCell>{renderSortLabel('description', t('description'))}</TableCell>
            <TableCell align="right">{renderSortLabel('amount', t('amount'))}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {displayedTransactions.map((transaction) => {
            // Determine the correct date field for display
            const transactionDateField = 'bank_transfer_id' in transaction ? 'transaction_date_time' : 'transaction_created_at';

            const lastFourDigits = isTopUpCardTransaction(transaction)
              ? pageData.user_accounts.card_accounts.find(
                  (e) => e.account_number === transaction.account_to
                )?.card_last_four_digits || ""
              : "";

            // Determine the correct description for display
            const transactionDescription =
              "bank_transfer_id" in transaction
                ? `${t(transactionTypeKey(transaction))} ${lastFourDigits}`
                : transaction.merchant_name
                ? t(transaction.transaction_type) +
                  " " +
                  transaction.merchant_name
                : t(transaction.transaction_type);

            return (
              <TableRow
                key={transaction.transaction_id}
                onClick={() => onRowClick(transaction)}
              >
                <TableCell className="table_body" style={{ cursor: "pointer" }}>
                  {formatDate(transaction[transactionDateField])}
                </TableCell>
                <TableCell
                  className="table_body"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  {transactionDescription}{" "}
                  {/* Display the correct description */}
                  {transaction.status === "PENDING" && (
                    <AccessTimeIcon
                      style={{ marginLeft: "5px", color: "#CCCCCC" }}
                    />
                  )}
                </TableCell>
                {transaction.status === "REJECTED" ||
                isNOOPTransaction(transaction) ||
                transaction.transaction_financial_status === "ON_HOLD" ? (
                  <TableCell className="table_body" align="right">
                    {transaction.status === "REJECTED"
                      ? t("cancelled")
                      : number_to_italian_currency(transaction.amount)}
                  </TableCell>
                ) : (
                  <TableCell
                    className={`table_body ${
                      isCreditTransaction(transaction)
                        ? "inward_money"
                        : "outward_money"
                    }`}
                    align="right"
                  >
                    {number_to_italian_currency(transaction.amount)}
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>

      </Table>
      <TablePagination
        style={{
          marginTop: '20px'
        }}
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredTransactions.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className='table_bottom'
        labelRowsPerPage={t('rows_per_page')}
        labelDisplayedRows={
          ({ from, to, count }) => {
            return '' + from + '-' + to + ` ${t('of')} ` + count
          }
        }
      />
    </div>
  );
}

function Page_Transactions({ onDataChange, ...props }) {
  const [account, setAccount] = React.useState('');
  const isMobile = useMediaQuery('(max-width:768px)');
  const [isLoading, setIsLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const navigate = useNavigate();
  const [pageData, setPageData] = useState(null);

  const [currentPage, setCurrentPage] = useState(0);
  // Other state and variables
  const [dateRange, setDateRange] = useState({
    from: startOfMonth(new Date()),
    to: endOfMonth(new Date()),
  });

  const handleDateChange = (name) => (newValue) => {
    setDateRange({ ...dateRange, [name]: newValue });
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleShowTransaction = (transaction) => {
    setSelectedTransaction(transaction);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedTransaction(null);
  };

  const [userAccounts, setUserAccounts] = useState([]);

  // Inside the get_page_data function, after fetching the data
  const processAccountsData = (userData) => {
    const cardAccounts = userData.user_accounts.card_accounts.map(account => ({
      name: `${t('card_label')} **** **** **** ${account.card_last_four_digits}`, // Masked PAN
      card_id: `${account.card_id}`,
      type: 'card',
      info: account.card_last_four_digits
    }));

    const currentAccounts = userData.user_accounts.current_accounts.map(account => ({
      name: `${t('bank_accounts')} ${account.account_number}`,
      account_id: `${account.account_id}`,
      type: 'account',
      info: account.account_number
    }));


    const allAccounts = [...currentAccounts, ...cardAccounts];
    setUserAccounts(allAccounts);
  };

  useEffect(() => {
    get_page_data();
  }, []);

  const handleChange = (event) => {
    setAccount(event.target.value);
  };

  const accountIcon = (type) => {
    return type === 'account' ?
      <AccountBalanceIcon style={iconStyle} /> :
      <CreditCardIcon style={iconStyle} />;
  };

  const getSelectedAccountType = () => {
    const selectedAccount = userAccounts.find(acc => acc.name === account);
    return selectedAccount ? selectedAccount.type : null;
  };

  useEffect(() => {
    if (account) {
      const accountType = getSelectedAccountType();
      if (accountType === 'card') {
        get_card_data();
      } else if (accountType === 'account') {
        get_bank_account_data();
      }
    }
  }, [account, dateRange, userAccounts]);

  const get_card_data = async () => {

    // Find the account using the name, then extract the card_id
    const selectedAccount = userAccounts.find(acc => acc.name === account);
    if (!selectedAccount) {
      console.error("No selected account found for card data fetch");
      return;
    }

    const transactionType = 'ALL'; // Adjust as needed
    setIsLoading(true);
    try {
      const data = await fetchCardTransactions(selectedAccount.card_id, transactionType, dateRange.from.toISOString().slice(0, 10).replace(/-/g, ''), dateRange.to.toISOString().slice(0, 10).replace(/-/g, ''), 'CET');

      if (data && data.transactions) {
        setTransactions(data.transactions);
      } else {
        setTransactions([]);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching card data:', error);
      setIsLoading(false);
    } finally {
      setIsLoading(false); // Ensure isLoading is set to false in both success and failure cases
    }
  };

  const get_bank_account_data = async () => {

    // Find the account using the name, then extract the account_id or any unique identifier
    const selectedAccount = userAccounts.find(acc => acc.name === account);
    if (!selectedAccount) {
      console.error("No selected account found for bank account data fetch");
      return;
    }

    // Use selectedAccount's account_id for fetching bank account data
    const transactionType = 'ALL'; // Adjust as needed
    setIsLoading(true);
    try {
      const data = await fetchBankAccountTransactions(selectedAccount.account_id, transactionType, dateRange.from.toISOString().slice(0, 10).replace(/-/g, ''), dateRange.to.toISOString().slice(0, 10).replace(/-/g, ''), 'CET');

      if (data && data.transactions) {
        // convert FDT specific transaction descriptions to user friendly transaction type
        data.transactions = data.transactions.map(transaction => {
          if (transaction.description && transaction.description.toLowerCase() === "epay purchase") {
            return {
              ...transaction,
              description: t('epay_purchase')
            };
          } else {
            return transaction;
          }
        });        
        setTransactions(data.transactions);
      } else {
        setTransactions([]);
      }
    } catch (error) {
      console.error('Error fetching bank account data:', error);
      setTransactions([]);
    } finally {
      setIsLoading(false); // Ensure isLoading is set to false in both success and failure cases
    }
  };

  const get_page_data = async () => {
    const token = getCookieValue('firebaseToken');
    setIsLoading(true);
    try {
      const apidata = await fetchPageData('transactions');

      let data = apidata.data;

      if (apidata.status === 'error') {
        if (apidata.fdt_error_code === 'TOKEN_EXPIRED') {
          navigate('/login?action=clean-cookie&reason=session_expired');
          return;
        }
        alert(t('error_getting_data_from_server'));
        return;
      }

      if (onDataChange) {
        onDataChange(data);
      }
      if (data) {
        setPageData(data);
        // Call processAccountsData here to process and set user accounts
        processAccountsData(data);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };

  return (
    <div style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '1500px' }}>
      {isModalOpen && (
        <Modal_TransactionDetails
          transaction={selectedTransaction}
          onClose={handleCloseModal}
          pageData={pageData}
          info={userAccounts.find(acc => acc.name === account)?.info}
          isCardTransaction={getSelectedAccountType() === 'card'}
        />
      )}
      <ThemeProvider theme={theme}>
        <Grid container spacing={3} style={{ display: 'flex', justifyContent: 'center' }}>
          {/* This Grid item appears to be empty. If not needed, consider removing it. */}
          <Grid item xs={12}></Grid>

          <Grid item xs={12} sm={12} style={{ minWidth: isMobile ? '300px' : '500px' }}>
            <Card style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', padding: '10px' }}>
              <CardContent>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="body1" style={{ ...textStyle_small_light, ...blue_title }}>
                    {t('bank_transactions')}
                  </Typography>
                  <DescriptionIcon color="primary" />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginTop: '20px' }}>
                <FormControl style={{ marginRight: '10px', width: '450px' }}>
                    <InputLabel id="account-select-label" className="transactions_input-label">
                      {t('select_account_bankaccount_or_card')}
                    </InputLabel>
                    <Select
                      labelId="account-select-label"
                      id="account-select"
                      value={account}
                      label={t('select_account')}
                      onChange={handleChange}
                      color="primary"
                      IconComponent={ListIcon}
                      className="transactions_select"
                      renderValue={(selected) => (
                        <Box display="flex" alignItems="center">
                          {userAccounts.find((item) => item.name === selected) ? accountIcon(userAccounts.find((item) => item.name === selected).type) : null}
                          <Typography variant="body2" noWrap style={{ marginLeft: '8px' }}>
                            {selected}
                          </Typography>
                        </Box>
                      )}
                    >
                      {userAccounts.map((item) => (
                        <MenuItem key={item.name} value={item.name} style={menuItemStyle}>
                          <ListItemIcon>
                            {accountIcon(item.type)}
                          </ListItemIcon>
                          <ListItemText primary={item.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    style={{ flexGrow: 2, marginRight: '10px' }}
                    name="search_filter_unique"
                    label={t('search_filter')}
                    variant="outlined"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    autoComplete="no-autofill"
                  />
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getCookieValue('language') === 'en' ? enGB: it}>

                    <div style={{ width: '160px', marginRight: '10px' }}>
                    <DatePicker
                          label={t('date_from')}
                          value={dateRange.from}
                          onChange={handleDateChange('from')}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              inputFormat="dd/MM/yyyy"
                            />
                          )}
                        />
                    </div>
                    <div style={{ width: '160px', marginRight: '10px' }}>

                      <DatePicker
                        label={t('date_to')}
                        value={dateRange.to}
                        onChange={handleDateChange('to')}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            inputFormat="dd/MM/yyyy"
                          />
                        )}
                      />
                    </div>
                  </LocalizationProvider>

                  <button
                    onClick={() => generatePDF(getSelectedAccountType(), transactions, { account, searchTerm, dateRange }, null, dateRange, pageData, userAccounts.find(acc => acc.name === account)?.info)}
                    style={{
                      paddingLeft: '5px',
                      border: 'none',
                      cursor: 'pointer',
                      fontSize: '16px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'right',
                      backgroundColor: 'transparent',
                    }}
                    aria-label="Export to PDF" // Accessibility label
                  >
                    <img src={pdf_icon} alt="PDF" style={{ height: '30px' }} />
                  </button>

                </div>
                {isLoading ? (
                  <Box display="flex" justifyContent="center" alignItems="center" height="300px">
                    <CircularProgress />
                  </Box>
                ) : (
                  <div>
                    <TransactionsTable
                      key={searchTerm}
                      transactions={transactions}
                      onPageChange={handlePageChange}
                      dateRange={dateRange}
                      searchTerm={searchTerm}
                      account={account}
                      onRowClick={handleShowTransaction}
                      pageData={pageData}
                    />
                  </div>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </ThemeProvider>
    </div>
  );
}

export default Page_Transactions;